import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  texts: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "80px auto",
    },
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  button: {
    marginTop: "80px",
    fontWeight: "700",
    textTransform: "initial",
    padding: "12px 28px",
    borderRadius: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

const VerifiedPage = props => {
  const classes = useStyles(props);

  return (
    <div>
      <Container maxWidth="lg">
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Requirements for getting verified on WhereToMine:
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            - pool must be active and listed on WhereToMine <br/>
            - pool fee and payment scheme must be public on pool website <br/>
            - pool must have support line or person of contact <br/>
            - pool must have social media presence (ex: Facebook, Twitter, Reddit, Discord, etc...) <br/>
            - have an API for 3rd party use cases <br/>
            - the pool must make a one time 50$ donation to WhereToMine in either Bitcoin, Ethereum or Litecoin <br/>
          </Typography>
        </div>
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Why must a pool make a 50$ donation in order to be verified?
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            - in the past we've offered verified status without donations to various pools, most of whom are defunct at this point <br/>
            - our goal is to let miners know that verified pools are trustworthy, and most of all, stable <br/>
            - donations will help with the continued development of WhereToMine <br/>
            - part of the donations will be used to give back to the community via giveaways or other forms of contests <br/>
          </Typography>
          
        </div>
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            What are the advantages of being Verified?
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            - all verified pools will be seen at the top of the list on their respective coin page <br/>
            - the verified ticker lets miners know that the pool is trustworthy and stable <br/>
            - verified pools gain spot on featured pools on main page of WhereToMine <br/>
            - verified pools can have logo next to their name (logo must be provided by pool operators) <br/>
            - verified pools have 7 days of sponsored pool Ad Spot <br/>
          </Typography>
          <Button
              className={classes.button}
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              endIcon={<ArrowForwardIosIcon fontSize="small" />}
              onClick={e => {
                const subject = "Pool Verification Request";
                const emailBody = `I would like my pool to be Verified`;
                document.location = "mailto:wheretomineio@gmail.com?subject=" + subject + "&body=" + emailBody;
              }}
            >
              Get Verified
            </Button>
        </div>
      </Container>
    </div>
  );
};

export default VerifiedPage;
