/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitter
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const removeElementsByClass = className => {
    var elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  // const removeElementsWithId = id => {
  //   const element = document.getElementById(id);
  //   if (typeof element !== "undefined" && element !== null) {
  //     document.body.removeChild(element);
  //   }
  // };

  // useEffect(() => {
  //   // Traffic
  //   removeElementsWithId("ct_cRKGjFkVWdI");

  //   const coinTrafficScript = document.createElement("script");
  //   coinTrafficScript.src = "https://appsha1.cointraffic.io/js/?wkey=jiO6sF";
  //   coinTrafficScript.async = true;
  //   document.body.appendChild(coinTrafficScript);

  //   return () => {
  //     document.body.removeChild(coinTrafficScript);
  //   };
  // }, []);

  useEffect(() => {
    // Header
    const headerScript = document.createElement("script");
    headerScript.src = "https://coinzillatag.com/lib/header.js";
    headerScript.async = true;
    document.head.appendChild(headerScript);

    let coinzillaHeader = window.coinzilla_header || [];
    window.coinzilla_header = coinzillaHeader;
    function czillaHeader() {
      coinzillaHeader.push(arguments);
    }
    czillaHeader("5905eb278afaba84635");

    // Sticky
    const stickyScript = document.createElement("script");
    stickyScript.src = "https://coinzillatag.com/lib/sticky.js";
    stickyScript.async = true;
    document.head.appendChild(stickyScript);

    let coinzillaSticky = window.coinzilla_sticky || [];
    window.coinzilla_sticky = coinzillaSticky;
    function czillaSticky() {
      coinzillaSticky.push(arguments);
    }
    czillaSticky("619315b8033a0ace40");

    return () => {
      removeElementsByClass("header-5905eb278afaba84635");
      document.head.removeChild(headerScript);

      removeElementsByClass("coinzilla-sticky");
      document.head.removeChild(stickyScript);
    };
  }, []);

  return (
    <Helmet
      script={
        [
          // {
          //   type: "text/javascript",
          //   "data-ad-client": "ca-pub-9971555671971518",
          //   async: true,
          //   src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
          // },
        ]
      }
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
