import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";

import VerifiedPage from "../components/Verified/VerifiedPage"

const Verified = () => (
  <Root>
    <SEO title="Getting the verified ticker for a pool" description="Here you will be able to see how to get a verified ticker for your cryptocurrency mining pool."/>
    <VerifiedPage />
  </Root>
);

export default Verified;
